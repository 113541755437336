import React, { useState } from "react"

import { ClockIcon, MailIcon, PhoneIcon } from "@heroicons/react/outline"
import Select from "./elements/Select"

export default function QuoteForm() {
  const [response, setResponse] = useState("")
  const handleSubmit = (e) => {
    e.preventDefault()
    let myForm = e.currentTarget
    let formData = new FormData(myForm)
    fetch("https://cms.asishipping.com/quote.php", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then((res) => setResponse("Thank you for your message!"))
      .catch((error) => alert(error))
  }

  return (
    <div className="pt-0 md:pt-10">
      <div className="relative">
        <div className="grid grid-cols-1">
          {/* Contact form */}
          <div className="lg:col-span-2 ">
           <div className=" max-w-3xl   mx-auto">
           <iframe
              style={{
                width: "100%",
                height: "850px",
                minHeight: "55vh",
                border: "none",
                overflowY: "auto",
              }}
              src="https://portal.asishipping.com/quotations/request?isFramed=true&brandColor=rgb(195,36,61)"
              frameborder="0"
            ></iframe>
           </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

const pols = [
  "Port of loading",
  "Freeport, Texas",
  "Jacksonville",
  "NORAD",
  "Philadelphia",
  "Baltimore",
  "AMPORTS",
  "Newark, NJ",
  "Boston",
  "Wilmington Delaware",
]

const pods = [
  "Port of discharging",
  "Beirut",
  "Lagos",
  "Aqaba",
  "Dubai",
  "Nouakchott",
  "Kuwait",
  "Cameroon",
  "Sierra Leon",
  "Tema",
  "Cotonou",
]
