import React from "react"
import CardsGrid from "../components/elements/CardsGrid"
import CenteredTextBlock from "../components/elements/CenteredTextBlock"
import PrimaryCTA from "../components/elements/PrimaryCTA"
import RightImageText from "../components/elements/RightImageText"
import IMG1 from "./../assets/images/roro5.jpg"
import IMG2 from "./../assets/images/container1.jpg"
import LinkCard from "../components/elements/LinkCard"

export default function ShipmentsPage({ data }) {
  return (
    <>
      <div className="divide-y divide-[#0c2352]">
        <CenteredTextBlock
          mainTitle="Maritime"
          title="RoRo Shipments"
          hideBackPattern
          less
          button={{ label: "Get a Quote", to: "/get-a-quote" }}
        >
          At American Shipping International, we strive to deliver the best
          experience when it comes to RoRo shipping, or finding the best moving
          companies that can clear your shipment at its destination.
          <div className="h-6" />
          Industry experience and customer feedback have allowed us to
          streamline the process ensuring superb service with guaranteed
          customer satisfaction. Our professional workforce, industry experts,
          cutting edge concepts and shipping coverage ensures that you’ll
          receive a premium service.
          <div className="h-6" />
          Our experts across ports will provide their guidance on taxation,
          packaging, customs clearance, insurance and more. We have made RoRo
          shipping easier than ever with fair pricing and a wide range of
          services to suit individual needs; be it shipping cars, household
          goods, boats, yachts, motorbikes shipping, heavy equipment, oversized
          cargos.
        </CenteredTextBlock>

        <CenteredTextBlock
          mainTitle="Maritime"
          title="Container Shipments"
          hideBackPattern
          less
          button={{
            label: "Browse our sailing schedule",
            to: "https://portal.asishipping.com/schedules",
          }}
        >
          ASI’s ocean freight forwarding services cover the entire freight
          transportation process by sea.
          <div className="h-6" />
          Our full container load service offers departures to and from Sallaum
          and Grimaldi ports such as New York, Baltimore, Savannah, Jacksonville
          and Houston. We also provide less than container load services which
          are considered to be the most flexible, and least expensive way to
          ship smaller and less time-sensitive cargoes.
          <div className="h-6" />
          We can help load your containers at our warehouses with utmost
          professionalism and care. As a rule of thumb, our team will work with
          you to understand your unique requirements before deciding on the
          right container.
        </CenteredTextBlock>

        <CenteredTextBlock
          mainTitle="Maritime"
          title="Breakbulk Shipments"
          hideBackPattern
          less
          button={{ label: "I’d like to reach out", to: "/contact" }}
        >
          While containers may be one of the convenient ways to ship cargoes,
          they are not always the best option. That’s where breakbulk shipping
          comes into play. Items that are oversized, overweight and are too
          large to fit into containers are considered breakbulk, and must be
          loaded individually.
          <div className="h-6" />
          Examples of breakbulk cargo include wind turbines, oil and gas
          machinery, pipes, turbine blades, ship propellers, generators, large
          engines and steel parts.
          <div className="h-6" />
          Overcome the obstacles of shipping such cargos by contacting us.
        </CenteredTextBlock>

        <CenteredTextBlock
          mainTitle="Maritime"
          title="Project Cargo"
          hideBackPattern
          less
          button={{ label: "Reach out", to: "/contact" }}
        >
          Considered as one of the most complex shipments in shipping, project
          cargo includes large, heavy, high value or complex parts of equipment.
          The term “project cargo” comes from the need to devise a detailed and
          well-thought plan that takes into consideration time, delivery date,
          budget and safety requirements.
          <div className="h-6" />
          There are several industries that rely on project cargo such as:
          <ul className="list-disc list-inside mt-4">
            <li>Oil and gas</li>
            <li>Renewable energy </li>
            <li>Aerospace</li>
            <li>Engineering</li>
            <li>Military</li>
          </ul>
        </CenteredTextBlock>

        <CenteredTextBlock
          mainTitle="Air"
          title="General Cargo"
          hideBackPattern
          less
          button={{ label: "Reach out", to: "/contact" }}
        >
          General cargo does not require extra precautions or special handling
          during air transport. It can either be:
          <ul className="list-decimal list-inside mt-4 mb-6">
            <li>
              Loose or separate cargo: considered as a single product and can
              directly be allocated to the most suitable space on the airplane.{" "}
            </li>
            <li>
              Palletised cargo: that is a group of goods shipped together and
              packed on a pallet or container.
            </li>
          </ul>
          These types of shipments belong to:
          <ul className="list-disc list-inside mt-4 mb-6">
            <li>Retail and most consumer goods</li>
            <li>Electronicd snf gadgets</li>
            <li>Dry goods</li>
            <li>Hardware</li>
            <li>Textiles </li>
            <li>Household goods</li>
            <li>Office equipment</li>
            <li>Sports equipment</li>
            <li>Clothes</li>
          </ul>
          Basically, when we talk about general cargo, think about your everyday
          objects
        </CenteredTextBlock>

        <CenteredTextBlock
          mainTitle="Air"
          title="Special Cargo"
          hideBackPattern
          less
          button={{ label: "Reach out", to: "/contact" }}
        >
          As the name may hint at, special cargo are goods that require special handling due to their nature and/or value. They may have to follow a specific criteria during their transport such as packaging, labeling, documentation and handling. 
          <div className="h-6" />
Special shipments can either be:

          <ul className="list-decimal list-inside mt-4 mb-6">
            <li>
            In gauge cargo: goods that can be shipped in a standard 6-wall container, flat-rack or open-top container.

            </li>
            <li>
            Out of gauge cargo: oversized goods that can’t be shipped in a flat rack or open-top container since they exceed them by length, width or height..

            </li>
          </ul>
          Items that fall into the special cargo category include: 
          <ul className="list-disc list-inside mt-4 mb-6">
            <li>Dangerous goods</li>
            <li>Animals</li>
            <li>Perishable cargo</li>
            <li>Wet cargo </li>
            <li>Time and temperature sensitive products such as medical kits</li>

          </ul>
          Basically, when we talk about general cargo, think about your everyday
          objects
        </CenteredTextBlock>


      </div>
      <PrimaryCTA
        title="We think you might be interested in learning about our transport modes."
        //subtitle="Let our experts manage the details of your shipping and logistics needs, so you don’t have to!"
        button={{ label: "Discover Them", to: "/how-we-ship" }}
      />
    </>
  )
}

const roroUsers = [
  {
    title: "Airports",
    content: "Lifts - Loaders - Tugs",
  },
  {
    title: "Agricultural Equipment",
    content: "Bailers - Combines - Pickers",
  },
  {
    title: "Automotive",
    content: "Ambulances - Buses - Cars - Trucks - Tractors - Trailers",
  },
  {
    title: "Construction Equipment",
    content: "Cranes -  Backhoes - Dozers - Loaders",
  },
  {
    title: "Oil, Gas & Energy Equipment",
    content: "Drill Rigs - Pumps",
  },
  {
    title: "Road Building Equipment",
    content: "Excavation And Extraction",
  },
]
