import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "../ui"
import { classNames } from "../../helpers"

const NewsGrid = () => {
	const data = useStaticQuery(graphql`
    {
      allWpPost {
        nodes {
            id
            title
            date
            excerpt
            featuredImage {
              node {
                url
              }
            }
            slug
            link
            categories {
              nodes {
                id
                name
                link
                slug
              }
            }
          }
        }
      
    }
  `)
  const posts = data?.allWpPost?.nodes;
  if(!posts[0]) return "";

  return (
    <div className={classNames("relative  pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8")}>
	  <Container>
      <div className="relative">
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.id}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={post.featuredImage.node.url}
                  alt="asi image, american shipping international"
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-primary">
                    <a href={post.categories?.nodes[0]?.link} className="hover:underline">
                      {post.categories?.nodes[0]?.name}
                    </a>
                  </p>
                  <a href={post.link} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    {/* <p className="mt-3 text-base text-gray-500">
                      {post.excerpt}
                    </p> */}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
	  </Container>
    </div>
  )
}

export default NewsGrid;