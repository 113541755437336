import {
  BadgeCheckIcon,
  CogIcon,
  GlobeIcon,
  HandIcon,
  HeartIcon,
  SupportIcon,
} from "@heroicons/react/solid"
import React from "react"
import CardsGrid from "../../components/elements/CardsGrid"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import ImageTextBlock from "../../components/elements/ImageTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import LinkCard from "../../components/elements/LinkCard"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import SectionAbout from "../../components/homepage/section-about"
import TextCTA from "../../components/homepage/TextCTA"
import IMG1 from "./../../assets/images/log1.jpg"
import IMG2 from "./../../assets/images/modes1.jpeg"
import IMG3 from "./../../assets/images/modes1.jpeg"

export default function CareersPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        mainTitle="Life at asi"
        title="Our inclusive, yet diverse, team-oriented culture runs throughout the entire company."
        hideBackPattern
        bottomComponent={
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:px-28 pt-28">
            <LinkCard title="ASI's Team" visible>
              Our team is one piece of the puzzle that makes everything come
              together so seamlessly.
            </LinkCard>

            <LinkCard title="ASI’s Vision" visible>
              To provide the highest level of reliable and dependable freight
              solutions to our clients. We foresee our expansion in the freight
              and logistics industry on a global scale to be the leading freight
              forwarder company.
            </LinkCard>

            <LinkCard title="ASI’s Mission" visible>
              To identify and meet the goals of our clients and build
              long-lasting relationships. We aim to nurture a thriving and
              inclusive work environment for our employees.
            </LinkCard>
          </div>
        }
      >
        We employ the most talented in the industry, and focus on building
        strong teams by communication and collaboration.
        <br />
        <br />
        We offer our team the chance to develop and advance. Were you to work at
        ASI, you will absolutely not experience any sort of career stagnation,
        for we’ll lend you a hand when it comes to your career advancement.
      </CenteredTextBlock>

      <CenteredTextBlock
        mainTitle="Apply now"
        title="Job Opportunities at ASI"
        hideBackPattern
		hideBottomElements
		hideTopElements
		className="border-t border-t-[#0c2352]"
      >
        If you think that the key to success is hard work and surrounding
        yourself with like-minded people, then congrats, you might have just
        landed yourself a place at ASI!

		<div className="px-4 py-4 !text-[18px] font-medium text-secondary mt-12 bg-secondary bg-opacity-10 rounded-sm">
			Sorry! No available job positions at the moment.
		</div>

      </CenteredTextBlock>

      <PrimaryCTA
        title="We Can Lend a Hand"
        subtitle="Let our experts manage the details of your shipping and logistics needs, so you don’t have to!"
        button={{ label: "Reach Out", to: "/contact" }}
      />
    </>
  )
}
