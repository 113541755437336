import { ArrowNarrowRightIcon } from "@heroicons/react/solid"
import React from "react"
import { classNames } from "../../helpers"
import Button from "./Button"
import LabelText from "./LabelText"
import Stars from "./Stars"

const stats = [
  { label: "Founded", value: "2021" },
  { label: "Employees", value: "5" },
  { label: "Beta Users", value: "521" },
  { label: "Raised", value: "$25M" },
]

export default function ImageTextBlock({
  children,
  mainTitle,
  title,
  button,
  image,
  link,
  variant = "default",
  className,
  contained = true,
  outlined,
  position = "left",
}) {
  const variants = {
    default: {
      root: "bg-white ",
      title: "text-black ",
      content: "text-black ",
      pattern: "text-gray-200",
    },
    primary: {
      root: "bg-primary ",
      title: "text-white ",
      content: "text-white text-opacity-70 ",
      pattern: "text-gray-200",
    },
    light: {
      root: "bg-primary-50 ",
      title: "text-primary ",
      content: "text-gray-900 text-opacity-70 ",
      pattern: "text-primary-200",
    },
  }
  return (
    <div
      className={classNames(
        "relative py-10",
        variants[variant].root,
        outlined && "border-t border-b border-color-[#0c2352]",
        className
      )}
    >
      <div
        className={classNames(
          "px-4 md:px-28 lg:flex  lg:gap-24 lg:items-center relative ",
          //contained && "lg:mx-auto lg:max-w-7xl ",
        )}
      >
        <div className={classNames("relative sm:py-16 lg:py-0 h-full md:w-2/5", position == "left" ? "order-first" : "order-last")}>
          <div
            aria-hidden="true"
            className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
          >
            <Stars
              className="absolute top-8 left-1/2 -ml-3 text-gray-200 lg:-right-14 lg:left-auto lg:top-8"
              width={350}
              fill="#e5e7eb"
            />
          </div>
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20 h-full">
            {/* Testimonial card*/}
            <div className="relative h-full rounded-xs shadow-xl overflow-hidden">
              <img
                className=" inset-0 h-full w-full object-cover "
                src={image}
                alt="side image"
              />
            </div>
          </div>
        </div>
        <div className="relative mx-auto max-w-md px-4 sm:max-w-xl sm:px-6 lg:px-0 md:w-3/5">
          {/* Content area */}

          <div className="py-12 sm:py-16 lg:py-20">
		  	{mainTitle && <h2 className="tracking-wider uppercase mb-8">
              <LabelText>{mainTitle}</LabelText>
            </h2>}
            <h2
              className={classNames(
                "text-3xl text-secondary font-extrabold tracking-tight sm:text-4xl leading-[1.2]",
                variants[variant].title
              )}
            >
              {title}
            </h2>
            <div
              className={classNames(
                "mt-6 text-black space-y-6",
                variants[variant].content
              )}
            >
              <p className="text-lg text-[20px] md:text-[24px] font-light leading-relaxed ">{children}</p>
            </div>
            {!!button && (
              <div className="mt-12">
                <Button label={button.label} link={button.to} />
              </div>
            )}
            {!!link && (
              <div className="mt-8">
                <a
                  href={link.to}
                  className="text-base font-medium text-primary-500"
                >
                  {link.label} &nbsp; &rarr;
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
