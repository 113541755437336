import { ArrowNarrowRightIcon } from "@heroicons/react/solid"
import React from "react"
import { classNames } from "../../helpers"
import { Button, Container } from "../ui"
import ChildrenNavigation from "./ChildrenNavigation"
import LabelText from "./LabelText"

export default function PageHeader({
  title,
  subtitle,
  image,
  navigation,
  parent,
  slug,
  bottomColor = "bg-white",
}) {
  if (navigation) {
    // sort navigation items alphabetically by title
    navigation.sort((a, b) => {
      if (a.menuOrder < b.menuOrder) return -1
      if (a.menuOrder > b.menuOrder) return 1
      return 0
    })
  }

  const nav = [
    { title: parent ? parent.title : title, slug: parent ? parent.slug : slug },
  ]
  if(parent) {
    nav.push({ title: title, slug })
  }

  return (
    <div className="mt-[85px]">
      {!!subtitle && !!image && (
      <div
        className={classNames(
          "relative h-[50vh]  shadow-xl overflow-hidden",
          bottomColor
        )}
      >
        <img className="h-full w-full object-cover " src={image} alt="cover image" />

        <div className="absolute left-0 right-0 top-0 bottom-0 z-10 bg-[rgba(13,18,26,0.6)] flex flex-col justify-center px-8 md:px-0 ">
          <div className="max-w-7xl w-full mx-auto">
            <div className="flex items-center mb-8">
              {nav.map((item, index) => (
                <NavLink
                  key={index}
                  {...item}
                  current={slug}
                  last={index == nav.length - 1}
                />
              ))}
            </div>
            <h1 className="text-4xl md:text-7xl max-w-4xl text-white font-semibold tracking-tight leading-[1.1]">
              {subtitle}
            </h1>
          </div>
        </div>

        {!!navigation[0] && (
          <ChildrenNavigation
            slug={slug}
            data={[
              { title: "Overview", slug: parent?.slug || slug },
              ...navigation,
            ]}
          />
        )}
      </div>
      )}
    </div>
  )
}

const NavLink = ({ title, slug, current, last }) => {
  const renderItem = () => {
    if (current == slug) {
      return (
        <span className="uppercase text-white">
          <LabelText light>{title}</LabelText>
        </span>
      )
    }
    return (
      <a href={`/${slug}`} className={classNames("uppercase text-white")}>
        {title}
      </a>
    )
  }
  return (
    <span className="flex items-center">
      {renderItem()}
      {!last && <ArrowNarrowRightIcon className="mx-4 h-[18px] text-white" />}
    </span>
  )
}
