import React from "react"
import ContactSection from "../components/ContactSection"
import CenteredTextBlock from "../components/elements/CenteredTextBlock"
import { Container } from "../components/ui"

export default function ContactPage({ data }) {
  return (
    <CenteredTextBlock
    mainTitle="Contact us"
    mainTitleTag="h1"
    title="We are always ready to help!"
    titleTag="h2"
      bottomComponent={<ContactSection />}
      hideBackPattern
    ></CenteredTextBlock>
  )
}
