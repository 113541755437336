import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/air-freight.jpeg"
import IMG2 from "./../../assets/images/ocean-freight.jpg"
import IMG3 from "./../../assets/images/land-freight.jpg"
import IMG4 from "./../../assets/images/modes1.png"
import CarriersLogos from "../../CarriersLogos"
import ImageTextBlock from "../../components/elements/ImageTextBlock"

export default function TransportationServicesPage({ data }) {
  return (
    <>
    <div className="divide-y divide-[#0c2352]">
      <CenteredTextBlock
        mainTitle="How we ship"
        title="Leading edge in transporting shipments"
        hideBackPattern
      >
        Whether by air, ocean, ground or intermodal, ASI
        will facilitate the movement of your cargos in the most efficient, cost
        effective and reliable manner.
        <br />
        <br />
        Not only that, but you’ll also get to take the advantage of deep
        visibility and control tools through a handful of technological services
        and platforms.
      </CenteredTextBlock>

      <ImageTextBlock
        mainTitle="Air Freight"
        title="Taking the sky for urgent shipments"
        image={IMG1}
      >
        If your cargo needs to move quickly and efficiently, ASI got you.
        Whether you’re in need of out-country or domestic air freight service,
        we have access to a number of airline schedules due to our strong
        relationships with major airlines. These long standing connections allow
        us to provide greater value and multiple options for your time-definite
        deliveries.
        {/* <br />
        <strong className="text-primary mt-4 mb-2 block">Benefits:</strong>
        <ul className="list-disc list-inside">
          <li>Frequent Departures</li>
          <li>Customized Solutions</li>
          <li>Multimodality</li>
        </ul> */}
      </ImageTextBlock>

      <ImageTextBlock
        mainTitle="Ocean Freight"
        title="Taking the seaview for break bulk shipments"
        image={IMG2}
        //variant="light"
        position="right"
        button={{to: "/loading-ports", label: "Check our ports of loading"}}
      >
        Whether you need to import or export shipments by ocean, our team will
        coordinate with trustable carriers to find the best option for
        transporting your cargo with the greatest value in mind. While we don’t
        own the vessels, we make all the required arrangements for shipping your
        cargo overseas. We maintain strong relationships with a wide network of
        ship owners to ensure the best possible service for forwarding your
        freight.
        {/* <br />
        <br />
        We repay our clients' trust and deliver that peace of mind through our
        unique solutions, personal service and more importantly by keeping our
        promises.
        <strong className="text-primary mt-4 mb-2 block">Benefits:</strong>
        <ul className="list-disc list-inside">
          <li>Cost-effective</li>
          <li>
            Transparency and control over your shipment via our tracking tool
          </li>
        </ul> */}
      </ImageTextBlock>

      <ImageTextBlock
        mainTitle="Land Freight"
        title="Hitting the road for short-distance"
        image={IMG3}
      >
        Our decisions are solely based on your specific shipping needs; not our
        schedules nor our available vehicle space. We select and utilize the
        safest and most experienced truckload companies to transport your
        freight.
        <br />
        <br />
        We can pick up cars and SUVs from major auctions such as IAA and Copart
        as well as dealerships before delivering them to the closest port. We
        can also pick up your construction equipment and trucks from auctions
        like Ritchie Bros, Alex Lyon and Son and IronPlanet. We do our best to
        ensure that these units are picked up within 2-3 days depending on the
        location and driver’s availability.
        {/* <strong className="text-primary mt-4 mb-2 block">Benefits:</strong>
        <ul className="list-disc list-inside">
          <li>Resourceful way to save money </li>
          <li>Eco-friendly as less CO2 is emitted </li>
          <li>Single customs document process </li>
          <li>Extensive road networks make rushed delivery possible </li>
          <li>
            Ability to handle short or long hauls in local or across borders of
            neighboring countries, even in rural areas
          </li>
        </ul> */}
      </ImageTextBlock>

     
      <CarriersLogos />
      </div>
      <PrimaryCTA
        title="We Can Lend a Hand"
        subtitle="Let our experts manage the details of your shipping and logistics needs, so you don’t have to!"
        button={{ label: "Reach Out", to: "/contact" }}
      />
    </>
  )
}
