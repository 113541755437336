import {
  BadgeCheckIcon,
  CogIcon,
  GlobeIcon,
  HandIcon,
  HeartIcon,
  SupportIcon,
} from "@heroicons/react/solid"
import React from "react"
import CardsGrid from "../../components/elements/CardsGrid"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import ImageTextBlock from "../../components/elements/ImageTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import LinkCard from "../../components/elements/LinkCard"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import SectionAbout from "../../components/homepage/section-about"
import TextCTA from "../../components/homepage/TextCTA"
import IMG1 from "./../../assets/images/log1.jpg"
import IMG2 from "./../../assets/images/modes1.jpeg"
import IMG3 from "./../../assets/images/modes1.jpeg"

export default function TeamPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        mainTitle="We are our team"
        title="United with a passion for global trade"
        hideBackPattern
        bottomComponent={
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:px-28 pt-28">
            <LinkCard title="ASI's Team" visible>
              Our team is one piece of the puzzle that makes everything come
              together so seamlessly.
            </LinkCard>
            
            <LinkCard title="ASI’s Vision" visible>
              To provide the highest level of reliable and dependable freight
              solutions to our clients. We foresee our expansion in the freight
              and logistics industry on a global scale to be the leading freight
              forwarder company.
            </LinkCard>

            <LinkCard title="ASI’s Mission" visible>
              To identify and meet the goals of our clients and build
              long-lasting relationships. We aim to nurture a thriving and
              inclusive work environment for our employees.
            </LinkCard>
          </div>
        }
      >
        ASI is a freight forwarder company founded and led by thinkers and
        makers who customize freight services and solutions that solve real
        shipment problems.
      </CenteredTextBlock>


      

      <PrimaryCTA
        title="Like what you see?"
        button={{ label: "Join our team", to: "/careers-at-asi" }}
      />
    </>
  )
}

const principles = [
  {
    title: "Integrity",
    content:
      "We never waver on honesty and trustworthiness nor on our support and dedication to our employees, clients and communities.",
    icon: <BadgeCheckIcon className="w-6 h-6" />,
  },
  {
    title: "Personalized Service",
    content:
      "No two clients are served in the exact manner. Each client is served by what suits his/her short-term and long-term business goals.",
    icon: <CogIcon className="w-6 h-6" />,
  },
  {
    title: "Empowerment",
    content:
      "We help our employees step up the ladder of growth and success. We want them to be the best version of themselves, and provide  ongoing training.",
    icon: <HandIcon className="w-6 h-6" />,
  },
  {
    title: "Exceptional Customer Service",
    content:
      "Our clients have open access to all levels of our employees whenever required; including even after hours. We believe it is the key to build long-lasting and fruitful relationships with our clients.",
    icon: <SupportIcon className="w-6 h-6" />,
  },
  {
    title: "Respect",
    content:
      "We will treat our employees and clients the way we wish to be treated: with respect for their opinions and feelings. We strive to understand their perspectives to foster a healthy and respectful environment.",
    icon: <HeartIcon className="w-6 h-6" />,
  },
  {
    title: "Social Responsibility",
    content:
      "We continuously try to give back to society by supporting  humanitarian, environmental and socially responsible initiatives. Case in point, we offer scholarships and sponsor local programs about development in Africa.",
    icon: <GlobeIcon className="w-6 h-6" />,
  },
]
