import React from "react"
import Card from "../elements/Card"
import { Container } from "../ui"
import { BookmarkIcon, CalendarIcon, CurrencyDollarIcon, DeviceMobileIcon, GlobeAltIcon, LocationMarkerIcon } from "@heroicons/react/solid"

const services = [
  { title: "Tracking", link: "https://portal.asishipping.com/tracking", icon: <LocationMarkerIcon className="w-4 h-4 text-primary" /> },
  { title: "Schedules", link: "https://portal.asishipping.com/schedules", icon: <CalendarIcon className="w-4 h-4 text-primary"/> },
  { title: "Quotation", link: "/get-a-quote", icon: <CurrencyDollarIcon className="w-4 h-4 text-primary"/> },
  { title: "Booking", link: "https://portal.asishipping.com/bookings", icon: <BookmarkIcon className="w-4 h-4 text-primary"/> },
  // { title: "Client Portal", link: "https://portal.asishipping.com/", icon: <GlobeAltIcon className="w-4 h-4"/> },
  // { title: "Mobile App", link: "/by-innovation-and-digitization", icon: <DeviceMobileIcon className="w-4 h-4"/> },
]

export default function EServices() {
  return (
    <Container>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4  mb-0 relative z-10">
        {services.map((item) => (
          <Card key={item.title} link={item.link}>
            <div className="flex items-center flex-col py-2">
              <div className="text-gray-600 ring-2 ring-primary rounded-full w-7 h-7 inline-flex items-center justify-center">{item.icon}</div>
              <div className="text-black mt-4 font-medium">{item.title}</div>
            </div>
          </Card>
        ))}
      </div>
    </Container>
  )
}
