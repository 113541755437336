import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import ImageTextBlock from "../../components/elements/ImageTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import TextWithWatermark from "../../components/elements/TextWithWatermark"
import IMG1 from "./../../assets/images/home1.jpg"
import IMG2 from "./../../assets/images/ocean-freight.jpg"
import IMG3 from "./../../assets/images/log1.jpg"
import TextCTA from "../../components/homepage/TextCTA"
import SectionAbout from "../../components/homepage/section-about"

export default function CompanyPage({ data }) {
  return (
    <>
      <SectionAbout
        title="Focused on Delivering What Matters to You, Fueled by Hardwork and Experience"
        button={{ label: "Discover Our Services", to: "/services" }}
        className="pb-32"
        mainTitle="Who we are"
      >
        Founded in 2016, American Shipping International LLC is a privately
        owned company that operates in Elizabeth, New Jersey. The team is led by
        professional and experienced employees who are always willing to go the
        extra mile for their customers. We pride ourselves on being the market
        leader in West African trade. Your satisfaction is nothing less than our
        success.
      </SectionAbout>
  
      <TextCTA line1="Why trust ASI," line2="Right?" />

      <div className="divide-y divide-[#0c2352]">
        <ImageTextBlock
          //mainTitle="Reason #1"
          title="Customized solutions that just make sense"
          image={IMG1}
          button={{ label: "Check out what we do", to: "/what-we-do" }}
          //outlined
        >
          We’re straightforward in how we present our features and customize our
          solutions. That's why our solutions and services are packed with
          immense flexibility that gives you outstanding outcomes.
        </ImageTextBlock>

        <ImageTextBlock
          //mainTitle="Reason #2"
          title="Customer support and satisfaction guaranteed"
          image={IMG3}
          button={{ label: "Check out our e-Tools", to: "/e-tools" }}
          //outlined
          position="right"
        >
          This isn’t arrogance talking, but our pride. We treat our clients as
          partners where we genuinely care about their success. We consult,
          collaborate and create personalized solutions to deliver rewarding
          results. We guarantee our work; thanks to our brilliant people.
          <br />
          <br />
          To facilitate our communication, we created a user-friendly client
          portal and mobile app, so you can go about your business whenever you
          want, and wherever you are.
        </ImageTextBlock>

        <ImageTextBlock
          //mainTitle="Reason #3"
          title="A legacy that lasts"
          image={IMG2}
          button={{ label: "Check out how we ship", to: "/how-we-ship" }}
          //outlined
        >
          You can bank on our industry experience of nearly 10 years. We have an
          expansive network of carriers and moving companies that can handle a
          wide variety of shipping services to and from the US. We serve in over
          40 countries, and have the expertise to handle every aspect of every
          shipment.
        </ImageTextBlock>

        
      </div>

     

      <PrimaryCTA
        title="We Can Lend a Hand"
        subtitle="Let our experts manage the details of your shipping and logistics needs, so you don’t have to!"
        button={{ label: "Reach Out", to: "/contact" }}
      />
    </>
  )
}
