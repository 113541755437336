import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/digi.jpg"
import IMG2 from "./../../assets/images/portal.jpeg"
import IMG3 from "./../../assets/images/roro-vessel.jpg"
import EServices from "../../components/homepage/eServices"
import MobileAppCTA from "../../components/homepage/MobileAppCTA"
import TextCTA from "../../components/homepage/TextCTA"
import LinkCard from "../../components/elements/LinkCard"

export default function ETools({ data }) {
  return (
    <>
      <CenteredTextBlock
        mainTitle="Inevitable Change"
        title="Going Digital: The Best Way Forward"
        hideBackPattern
      >
       This has never been more important in a world where the speed of digital transformation is disrupting every industry in the world, and changing competitive landscapes.
       <div className="h-4" />
At ASI, we celebrate simplicity and look to remove complexity in the logistics and shipping industry for the sole purpose of contributing to a positive impact for our clients.

        <div className="h-4" />
        As the world relies on technology, so do we. With our cutting-edge technology tools, we made it easier to import and export your shipments. 
      </CenteredTextBlock>

      <TextCTA
        title="We are ASI"
        line1="Delivering Quality Freight"
        line2="Solutions with Exceptional Expertise"
      />

      <CenteredTextBlock
        mainTitle="Available Tools"
        title="Our clients will enjoy 6 unique e-Tools"
        hideBackPattern
        bottomComponent={
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:px-28 pt-28">
            {tools.map((tool, index) => (
              <LinkCard title={tool.title} link={tool.link} visible>
                {tool.description}
              </LinkCard>
            ))}
          </div>
        }
      ></CenteredTextBlock>

      <PrimaryCTA
        title="We Can Lend a Hand"
        subtitle="Let our experts manage the details of your shipping and logistics needs, so you don’t have to!"
        button={{ label: "Reach Out", to: "/contact" }}
      />
    </>
  )
}

const tools = [
  {
    title: "Shipping Schedules",
    description:
      "At ASI, you can check shipping schedules by carrier, origin and destination. ",
    link: "https://portal.asishipping.com/schedules",
  },
  {
    title: "Online Quotes",
    description:
      "Instantly receive a quote after you enter your information as a shipper as well as you package details.",
    link: "/get-a-quote",
  },
  {
    title: "Bookings",
    description: "One easy contact. Bookings made easier online. ",
    link: "https://portal.asishipping.com/bookings",
  },
  {
    title: "Tracking",
    description:
      "Our digital tracking solution gives you real-time detailed progress of your shipment without the need to pick-up the phone. All you have to do is enter your VIN or serial number.",
    link: "https://portal.asishipping.com/tracking",
  },
  {
    title: "Client Portal",
    description:
      "This is the fastest way to help our clients. It’s much faster than emails, and more efficient than phone calls. Most importantly, we don’t make our clients wait.",
    link: "https://portal.asishipping.com/",
  },
  {
    title: <>Mobile Application <span className="text-[16px] block uppercase text-primary">Coming Soon</span></>,
    description:
      "Round-the-clock monitoring of cargoes, scheduling, booking, orders’ processing, and much more is all possible with just a tap. ",
  },
]
