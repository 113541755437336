import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/modes1.png"
import IMG2 from "./../../assets/images/digit1.jpg"
import IMG3 from "./../../assets/images/roro-vessel.jpg"
import ImageTextBlock from "../../components/elements/ImageTextBlock"

export default function ServicesPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        title="Bridging the gap between customized shipping services and pioneering technology"
        hideBackPattern
        className="border-b border-b-[#0c2352]"
      >
        We can help you stay ahead of new freight opportunities with proven
        strategies from our experienced team. Hand in hand, we’ll create new
        edge experiences, implement effective freight strategies, modernize and
        simplify your shipping operations with cutting-edge technology and
        innovative services.
      </CenteredTextBlock>
      <div className="divide-y divide-[#0c2352]">
        <ImageTextBlock
          mainTitle="Freight Services"
          title="What We Do"
          image={IMG1}
          button={{ label: "Learn more", to: "/what-we-do" }}
          //outlined
        >
          Hand in hand, our clients emerge stronger and ready to take on the
          next challenge. Here at ASI, clients are treated as our partners. Our
          team attentively listens to their needs, requests and feedback. This
          engaging manners ensures that we are advising our clients on the best
          services that are highly effective and essential to the way they
          operate.
          <div className="h-4" />
          The range of our solutions can vary from simple transport or to
          complex operations.
          <div className="h-4" />
          We optimize all activities around information, material and financial
          flow. We provide reliable end-to-end solutions tailored to our
          customers’ needs with a special commitment to industry specific
          requirements.
        </ImageTextBlock>

        <ImageTextBlock
          mainTitle="Transport Modes"
          title="How We Ship"
          image={IMG3}
          button={{ label: "Learn more", to: "/how-we-ship" }}
          //outlined
          position="right"
        >
          At ASI, we minimize the challenges often associated with global
          shipping; giving you peace of mind and the competitive advantage
          needed for profitable growth.
          <br />
          <br />
          We have deep-rooted relationships with a number of transportation
          agents around the world, giving you the flexibility of shipping your
          cargoes by air, sea or inland.
          <br />
          <br />
          We also provide pick up and delivery services to different ports, and
          handle all compliance matters; saving you significant time and money.
        </ImageTextBlock>

        <ImageTextBlock
          mainTitle="Shipments"
          title="What We Ship"
          image={IMG2}
          button={{ label: "Learn more", to: "/what-we-ship" }}
          //outlined
        >
          American Shipping International is here to take the pain out of
          freight. Dealing with multiple domestic and international rules and
          requirements, and connecting with carriers can be overwhelming. Yet,
          by partnering with us, you enjoy a single designated representative
          who coordinates everything.
          <br />
          <br />
          Our global network is managed and graded against the highest standards
          of service delivery.
        </ImageTextBlock>
      </div>
      <PrimaryCTA
        title="Operating in 45+ countries"
        subtitle="Goods are loaded, secured and ready to be transported from either Sallaum ports or Grimaldi ports."
        button={{ label: "Loading Ports", to: "/loading-ports" }}
      />
    </>
  )
}
