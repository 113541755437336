import React, { useState } from "react"

import { ClockIcon, MailIcon, PhoneIcon } from "@heroicons/react/outline"
import {ContactForm} from "@logisoft/elements"

export default function ContactSection() {

  const [response, setResponse] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault()
    let myForm = e.currentTarget
    let formData = new FormData(myForm)
    fetch("https://cms.asishipping.com/contact.php", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then((res) => setResponse("Thank you for your message!"))
      .catch((error) => alert(error))
  }

  return (
    <div className="px-8 md:px-28 pt-28">
      <div className="relative bg-white border border-[#0c2352] overflow-hidden ">
        <h2 className="sr-only">Contact us</h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 divide-x divide-[#0c2352]">
          {/* Contact information */}
          <div className="relative overflow-hidden py-10 px-6 bg-white sm:px-10 xl:p-12">
         
            <h3 className="text-lg font-medium text-secondary">
              American Shipping International
            </h3>
            <p className="mt-6 text-base text-black max-w-3xl">
            1 Slater Driver Elizabeth Nj 07206
            </p>
            <dl className="mt-8 space-y-6">
              <dt>
                <span className="sr-only">Phone number</span>
              </dt>
              <dd className="flex text-base text-black">
                <PhoneIcon
                  className="flex-shrink-0 w-6 h-6 text-primary"
                  aria-hidden="true"
                />
                <span className="ml-3">+1 908-224-1122</span>
              </dd>
              <dt>
                <span className="sr-only">Email</span>
              </dt>
              <dd className="flex text-base text-black">
                <MailIcon
                  className="flex-shrink-0 w-6 h-6 text-primary"
                  aria-hidden="true"
                />
                <span className="ml-3">info@asishipping.com</span>
              </dd>
              <dd className="flex text-base text-black">
                <ClockIcon
                  className="flex-shrink-0 w-6 h-6 text-primary"
                  aria-hidden="true"
                />
                <span className="ml-3">Mon - Fri 09:00 am - 05:00 pm</span>
              </dd>
            </dl>
          </div>

          {/* Contact form */}
          <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <h3 className="text-lg font-medium text-gray-900 mb-10">
              Send us a message
            </h3>
            <iframe
              style={{
                width: "100%",
                height: "850px",
                minHeight: "55vh",
                border: "none",
                overflowY: "auto",
              }}
              src="https://portal.asishipping.com/contact?isFramed=true"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}
