import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/customs.jpg"
import IMG2 from "./../../assets/images/cargo-insurance.jpeg"
import IMG3 from "./../../assets/images/digi.jpg"
import IMG4 from "./../../assets/images/proc.jpg"
import IMG5 from "./../../assets/images/warehousing.jpg"
import IMG6 from "./../../assets/images/consol.jpg"
import HyperLink from "../../components/elements/HyperLink"
import ImageTextBlock from "../../components/elements/ImageTextBlock"
import LinkCard from "../../components/elements/LinkCard"

export default function FreightServicesPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        title="Freight Services and Solutions built around you"
        hideBackPattern
        className="border-b border-b-[#0c2352]"
      >
        When it comes to shipping your products worldwide, trust is necessary
        when selecting your logistics partner. By the same token, working with
        an inexperienced freight forwarder can result in supply chain
        bottlenecks, and have damaging consequences on your business.
        <br />
        <br />
        Our experienced service team possesses a broad understanding of what
        ocean, air and ground{" "}
        <HyperLink to="/how-we-ship">transportation</HyperLink> entails, and
        will construct a plan that is equipped to handle your global shipping
        needs. We work diligently around the clock to ensure all your import and
        export activities are handled with efficiency, accuracy and tenacity.
      </CenteredTextBlock>

      <div className="divide-y divide-[#0c2352]">
        <ImageTextBlock
          mainTitle="Freight Services Consultation"
          title="Every company, small or large, has its own unique supply chain and processes."
          image={IMG1}
          button={{ label: "Contact Us", to: "/contact" }}
          //outlined
        >
          With the rules of global shipping always changing, you need a
          logistics company that stays up to date on new policies and updated
          regulations. We are at the forefront of keeping on top of obtaining
          and excelling in the latest domestic and international logistics news.
          <br />
          <br />
          Our advising services seek to increase productivity, and cut overall
          unnecessary costs for your business. In addition to being a dedicated
          shipping partner, we can also offer guidance for preparing the needed
          documents, warehousing options, consolidation and deconsolidation
          services, cargo insurance, to name a few.
        </ImageTextBlock>

        <CenteredTextBlock
          mainTitle="Warehousing Services"
          title="Warehousing services to optimize your business’s overall productivity"
          hideBackPattern
          less
          bottomComponent={
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 gap-y-8 px-8 lg:px-28 pt-28">
              <LinkCard title="Order Fulfillment" visible>
                This includes order processing, packaging and labeling and
                shipping.
              </LinkCard>

              <LinkCard title="Inventory Control" visible>
                Warehousing services can be used for inventory-control purposes.
                A company can utilize warehouse cycle counts to analyze delivery
                of goods to consumers, so it can take the necessary steps to
                improve its cycle count. Inventory control also makes it easy
                for companies to eliminate and reduce receiving errors.
              </LinkCard>

              <LinkCard title="Contract Warehousing" visible>
                This is given to companies that aim to establish distribution
                centers in certain regions. A business can set up a warehouse
                facility overnight, and start delivering goods to consumers
                within a few days. It is popular with startups and businesses
                experiencing fast growth that may lack financial resources to
                set up their own warehouses.
              </LinkCard>

              <LinkCard title="Cross docking" visible>
                This allows clients to route products from 1 or multiple
                manufacturing sites to a strategically located distribution
                center. Goods are then deconsolidated into multiple shipments,
                and delivered to consumers.
              </LinkCard>

              <LinkCard title="Transport" visible>
                A warehousing firm can also offer transport services to its
                clients by sea, land or air. Some companies may also offer
                tracking services to their clients to track the movement of
                goods from one location to another in real-time.
              </LinkCard>

              <LinkCard title="Transloading" visible>
                Transloading is the transfer of cargoes from one mode of
                transport to another i.e. from a truck to an airplane.
              </LinkCard>
            </div>
          }
        >
          Companies that ship often need services to help maintain their overall
          supply chain; one of which is warehousing. Through our network of
          agencies and professional agents dispersed around the world, we have
          the resources to help you manage your cargoes whether at origin or
          destination.
          <br />
          <br />
          Our consumer-centered model paves the way for our offered warehousing
          services to optimize your business’s overall productivity,
          profitability and customer-satisfaction.
        </CenteredTextBlock>

        <CenteredTextBlock
          mainTitle="Consolidation and Deconsolidation Services"
          hideBackPattern
          less
          bottomComponent={
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 gap-y-8 px-8 lg:px-28 pt-28">
              <LinkCard title="Less Freight Shipping Cost" visible>
                Freight consolidation is known to have a huge impact on shipping
                costs, for shippers only pay for the amount of space booked for
                their cargo as opposed to FCL & FTL shipments. Similarly,
                freight deconsolidation when done correctly and timely can save
                cost in the final distribution of the goods.
              </LinkCard>

              <LinkCard title="On Time Inventory" visible>
              Shippers can consolidate and ship their products whenever they want to rather in LCL or LTL than waiting for the container to fill up. Then, the cargo can be deconsolidated and stored in places closer to the market; leading to quicker inventory movement, replenishment and availability in the market.
              </LinkCard>

              <LinkCard
                title="Availability of Your Products in the Market"
                visible
              >
                Shippers can consolidate their freight based on the seasonal
                trends and market demands. Thus, consolidation and
                deconsolidation of freight help shippers ensure their products
                are available to the end consumers at all times.
              </LinkCard>
            </div>
          }
        >
          <h3 className="text-3xl text-black font-extrabold tracking-tight mb-4">
            Consolidation
          </h3>
          As the very meaning of the word suggests, consolidation in logistics
          also means combining several small packages or shipments into one big
          shipment. This helps shippers lower freight shipping costs and reduce
          transport time. Yet, consolidated shipments will go through more
          handling requirements and touch points during their journey. Rest
          assured, ASI’s team will properly plan the consolidation of your
          goods.
          <h3 className="text-3xl text-black font-extrabold tracking-tight mb-4 mt-4">
            Deconsolidation
          </h3>
          In freight shipping, deconsolidation is the separation or breaking
          down of one big shipment into smaller packages or shipments, so that
          they can be transported to their respective destinations which could
          be a store, warehouse or an end customer.
          <div className="text-center mt-28">
            <h3 className="text-2xl text-secondary font-extrabold tracking-tight mb-4 mt-4">
              Benefits of Consolidation and Deconsolidation
            </h3>
            <p>
              Freight consolidation and deconsolidation are like two sides of a
              coin and are equally important.
            </p>
          </div>
        </CenteredTextBlock>

        <ImageTextBlock
          mainTitle="Customs and Compliance "
          title="Handling the complexities of cross-border shipping"
          image={IMG3}
          button={{ label: "Reach out", to: "/contact" }}
          //outlined
          position="right"
        >
          With the everchanging international compliance regulations, we will
          handle the complexities of cross-border shipping. As long as one of
          ASI’s team is by your side, we ensure a highly secure and expeditious
          supply chain for our customers from beginning to end. Needless to say,
          simplification, transparency, and electronic exchanges between you and
          customs authorities.
          <br />
          <br />
          Our team has the knowledge and experience to clear freight through
          customs with speed and accuracy; keeping your imports and exports
          moving efficiently to their destination and right on schedule.
        </ImageTextBlock>
      </div>
      <PrimaryCTA
        title="We Can Lend a Hand"
        subtitle="Let our experts manage the details of your shipping and logistics needs, so you don’t have to!"
        button={{ label: "Reach Out", to: "/contact" }}
      />
    </>
  )
}
