import React from "react"
import ContactSection from "../components/ContactSection"
import CenteredTextBlock from "../components/elements/CenteredTextBlock"
import QuoteForm from "../components/QuoteForm"
import { Container } from "../components/ui"

export default function QuotePage({ data }) {
  return (
    <CenteredTextBlock
      mainTitle="Get a Quote"
      mainTitleTag="h1"
      title="You are one step away from excellence!"
      titleTag="h2"
      bottomComponent={<QuoteForm />}
      hideBackPattern
    ></CenteredTextBlock>
  )
}
