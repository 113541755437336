import { ClockIcon, DeviceMobileIcon, DocumentIcon, IdentificationIcon, LocationMarkerIcon, MailIcon, PhoneIcon, PrinterIcon } from "@heroicons/react/outline"
import React from "react"
import LabelText from "../components/elements/LabelText"
import LinkCard from "../components/elements/LinkCard"
import PrimaryCTA from "../components/elements/PrimaryCTA"
import { Container } from "../components/ui"
import { classNames } from "../helpers"

export default function DestinationsPage({ data }) {
  return (
    <>
      <div className="py-28">
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {destinations.map((item) => (
              <LinkCard title={item.name} visible>
                <span>
                  <LabelText>{item.line}</LabelText>
                </span>
                <ul className="mt-8">
                  <li className="mb-4">
                    <span className="font-semibold text-secondary pr-4">
                      <LocationMarkerIcon className="w-6 h-6 inline -mt-2 mr-2 text-primary" />
                      Delivery Address:
                    </span>
                    <span className="text-black">{item.deliveryAddress}</span>
                  </li>
                  {!!item.receivingHours && <li className="mb-4">
                    <span className="font-semibold text-secondary pr-4">
                      <ClockIcon className="w-6 h-6 inline -mt-1 mr-2 text-primary" />
                      Receiving Hours:
                    </span>
                    <span className="text-black">{item.receivingHours}</span>
                  </li>}
                  {!!item.tel && <li className="mb-4">
                    <span className="font-semibold text-secondary pr-4">
                      <PhoneIcon className="w-6 h-6 inline -mt-2 mr-2 text-primary" />
                      Telephone:
                    </span>
                    <span className="text-black">{item.tel}</span>
                  </li>}
                  {!!item.mobile && <li className="mb-4">
                    <span className="font-semibold text-secondary pr-4">
                      <DeviceMobileIcon className="w-6 h-6 inline -mt-2 mr-2 text-primary" />
                      Mobile:
                    </span>
                    <span className="text-black">{item.mobile}</span>
                  </li>}
                  {!!item.email && <li className="mb-4">
                    <span className="font-semibold text-secondary pr-4">
                      <MailIcon className="w-6 h-6 inline -mt-1 mr-2 text-primary" />
                      Email Address:
                    </span>
                    <span className="text-black">{item.email}</span>
                  </li>}
                  {!!item.fax && <li className="mb-4">
                    <span className="font-semibold text-secondary pr-4">
                      <PrinterIcon className="w-6 h-6 inline -mt-2 mr-2 text-primary" />
                      Fax:
                    </span>
                    <span className="text-black">{item.fax}</span>
                  </li>}
                  {!!item.requiredDocs && <li className="mb-0">
                    <span className="font-semibold text-secondary pr-4">
                      <DocumentIcon className="w-6 h-6 inline -mt-2 mr-2 text-primary" />
                      Required Documents:
                    </span>
                    <span className="text-black">{item.requiredDocs}</span>
                  </li>}

                  {item.line == "Grimaldi" && 
                    <li className="mt-4">
                      <span className={classNames("font-semibold  pr-4", item.twicRequired ? "text-primary" : "text-gray-400")}>
                        <IdentificationIcon className={classNames("w-6 h-6 inline -mt-1 mr-2 ", item.twicRequired ?  "text-primary" : "text-gray-400")} />
                        {item.twicRequired ? "TWIC Card is required" : "TWIC Card is not required"}
                      </span>
                    </li>
                  }
                </ul>
              </LinkCard>
            ))}
          </div>
        </Container>
      </div>

      <PrimaryCTA
        title="Found your next shipment’s destination?"
        subtitle="Reach out to discuss your shipping requirements."
        button={{ label: "Get in touch", to: "/contact" }}
      />
    </>
  )
}

const destinations = [
  {
    name: "Freeport, TX",
    deliveryAddress: "Horizon Terminal Services LLC, 1341A Pine Street, Freeport, Texas 77541",
    tel: "979-415-0225",
    email: "fpocustserv@horizonterminals.com",
    receivingHours: "Monday through Friday 8 AM to 4 PM",
    requiredDocs: "",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Sallaum Lines",
  },
  {
    name: "Jacksonville, FL",
    deliveryAddress: "Horizon Terminal Services, 5263 Intermodal Drive, Jacksonville, FL 32226",
    tel: "904-7571227",
    email: "",
    receivingHours: "Monday through Friday 8 AM to 4 PM",
    requiredDocs: "Original title, 2 Copies frond and back of title, 5 copies of the dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Sallaum Lines",
  },
  {
    name: "Norad Davisville, RI",
    deliveryAddress: "30 Marine Rd. North Kingstown, RI 02852.",
    tel: "(401) 667-7000 x 124",
    email: "",
    receivingHours: "Monday through Friday 7 AM to 3 PM",
    requiredDocs: "Original title, 5 copies front and back of the Title, 4 copies of dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Sallaum Lines",
  },
  {
    name: "Philadelphia, PA",
    deliveryAddress: "Pier 122, Philadelphia, PA 19112",
    tel: "412-504-1607",
    email: "",
    receivingHours: "Monday through Friday 7:30 AM to 2:30 PM",
    requiredDocs: "Original title, 1 copy of title (front & back), 3 copies of dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Sallaum Lines",
  },
  {
    name: "Baltimore, MD – SOUTH LOCUS",
    deliveryAddress: "TARTAN TERMINALS2025 E. MCCOMAS ST.Baltimore, MD 21230",
    tel: "443-510-4646",
    email: "",
    receivingHours: "",
    requiredDocs: "Original title, 3 copies of the front and back of the title, and 3 copiesof the dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Sallaum Lines",
  },
  {
    name: "BALTIMORE, MD – AMPORTS",
    deliveryAddress: "3201 VERA STREET Baltimore MD 21226",
    tel: "410-843-7141",
    email: "amportspov@amports.com",
    receivingHours: "Monday through Friday 8 AM to 4 PM",
    requiredDocs: "Original title, 3 copies of the front and back of the title, and 3 copiesof the dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Sallaum Lines",
  },
  {
    name: "Newark, NJ",
    deliveryAddress: "138 Marsh St, NEWARK, NJ 07114",
    tel: "973-522-0999",
    email: "",
    receivingHours: "Monday through Friday 8 AM to 4 PM",
    requiredDocs: "Original title, 3 copies of the dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Sallaum Lines",
  },
  {
    name: "Boston, MA",
    deliveryAddress: "Diversified Automotive, Inc. Boston Autoport, 100 Terminal Street Charlestown, MA 02129",
    tel: "800-666-9007 Ext: 1157",
    email: "",
    receivingHours: "Monday through Friday 7 AM to 5 PM",
    requiredDocs: "",
    mobile: "781-690-5964",
    fax: "617-242-4455",
    twicRequired: false,
    line: "Sallaum Lines",
  },
  
  {
    name: "Baltimore, Dundalk",
    deliveryAddress: "2700 BROENING HWY BALTIMORE, MD 21222",
    tel: "443-769-1987",
    email: "",
    receivingHours: "",
    requiredDocs: "Original Title, 6 copies of front & back of title, 6 copies of dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Grimaldi",
  },
  {
    name: "Providence, RI",
    deliveryAddress: "Waterson Terminal Services LLC35 Terminal RoadProvidence, RI 02905",
    tel: "401-461-9900",
    email: "",
    receivingHours: "",
    requiredDocs: "Original Title, 5 copies of front & back of the title, 5 copies of dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: true,
    line: "Grimaldi",
  },
  {
    name: "Newark, NJ",
    deliveryAddress: "Ports America, Port Newark Auto TerminalBerths 16 & 18171 Marsh StreetNewark, NJ 07114",
    tel: "973-465-6812",
    email: "",
    receivingHours: "",
    requiredDocs: "Original Title, 6 copies of front & back of the title, 6 copies of dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Grimaldi",
  },
  {
    name: "Wilmington, DE",
    deliveryAddress: "AutoPort Inc.203 PIGEON POINT ROADNEW CASTLE, DE 19720",
    tel: "302-655-1171",
    email: "",
    receivingHours: "",
    requiredDocs: "Original Title, 3 copies of front & back of the title, 5 copies of the dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Grimaldi",
  },
  {
    name: "Savannah, GA",
    deliveryAddress: "Ocean Terminal 55 Lathrop Ave SAVANNAH, GA 31401",
    tel: "",
    email: "",
    receivingHours: "",
    requiredDocs: "Original Title, 4 copies of front & back of the title, 4 copies of dock receipt, 4 copies of the letter of intent.",
    mobile: "",
    fax: "",
    twicRequired: true,
    line: "Grimaldi",
  },
  {
    name: "Freeport, TX",
    deliveryAddress: "HORIZON AUTO LOGISTICS (HAL) 1341A PINE STREET FREEPORT, TX 77541",
    tel: "979-415-0225",
    email: "",
    receivingHours: "",
    requiredDocs: "Original Title, 5 copies of the front & back of the title, 5 copies of dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: false,
    line: "Grimaldi",
  },
  {
    name: "Jacksonville, FL",
    deliveryAddress: "Horizon Terminal Service 5263 INTERMODAL DRIVE JACKSONVILLE, FL. 32226",
    tel: "904-757-1227",
    email: "",
    receivingHours: "",
    requiredDocs: "Original Title, 5 copies of the front & back of the title, 4 copies of dock receipt.",
    mobile: "",
    fax: "",
    twicRequired: true,
    line: "Grimaldi",
  },
]
