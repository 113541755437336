import { navigate } from "gatsby"
import React from "react"
import { classNames } from "../../helpers"

const ChildrenNavigation = ({ data, slug }) => {
  return (
    <div className="absolute bottom-0 z-20 left-0 right-0 flex items-center bg-secondary-600 whitespace-nowrap  bg-opacity-60 backdrop-blur-sm sm:justify-center overflow-x-auto">
      {data.map((item, index) => (
        <div
          key={index}
          onClick={() => navigate(`/${item.slug}`)}
          className={classNames(
            "px-6 py-4 font-medium text-sm cursor-pointer border-t-2  self-stretch ",
            item.slug == slug
              ? "text-white   border-primary"
              : "text-white text-opacity-70 border-transparent"
          )}
        >
          {item.title}
        </div>
      ))}
    </div>
  )
}

export default ChildrenNavigation
